import {React, useEffect, useState} from 'react';
import axios from "axios";
import { Navigate, Link } from "react-router-dom"
// import { Table, Nav ,Spinner, Modal, Button, Form} from 'react-bootstrap'
import Moment from 'moment';
import config from '../config'
// import { Link } from 'react-router';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee, faEdit } from '@fortawesome/free-solid-svg-icons'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css'; // Assuming you want to add additional styles

  

const About = (props) => {
 

  const mystyle = {
             
    backgroundColor: "white",
   
  };
 



 
  return (
      <>
  
      <div className="container-fluid saraData">
        <div className="home-banner">
            <header className="header">
            <Link to="/">   <div className="companyName">
        <img src="images/logo.png" alt="Company Logo" />
        <h1>nvasaverasatta<span>.com</span></h1>
</div>      </Link>
                <div className="social-links ms-auto">
                    <div className="social-content">
                        <i className="icon-whatsapp"></i>
                        <div className="link-text">
                            <div className="chatOn greenTxt">Chat On</div>
                            {/* <div className="appTxt">What's App</div> */}
                        </div>
                    </div>
                    <div className="social-content">
                        <i className="icon-phone"></i>
                        <div className="link-text">
                            {/* <div className="chatOn orangeTxt">CALL US NOW</div> */}
                            <div className="appTxt"><a href="tel:+919914700000">9914700000</a></div>
                        </div>
                    </div>
                </div>
            </header>
            

            <div className="bg-white p-3">
            <br></br>    <br></br>    <br></br>    <br></br>
            <div className="article-container">
      <h1 className="title">Understanding Satta King in India: A Closer Look at Punjab, Gali, Disawer, Faridabad, Ghaziabad, and Punjab</h1>
      
      <section className="section">
        <h2 className="heading">History of Satta King Result?</h2>
        <p className="content">
          A common type of gambling in India is Satta King, which is rooted in the number-betting custom. It is a component of the larger, 1960s-era Satta Matka system. Satta Matka developed into a sophisticated system of wagering on random numbers. It originated as a lottery involving wagers on the opening and closing rates of cotton relayed from the New York Cotton Exchange to the Bombay Cotton Exchange.
        </p>
        <p className="content">
          "King" highlights the meaning of the game's winner, and "Satta" denotes wagering or gambling. This type of gambling has become especially well known in Punjab, Faridabad, Ghaziabad, Gali, Disawer, Himachal Pradesh, and the Himalayas over time. It has also spread to other areas.
        </p>
      </section>

      <section className="section">
        <h2 className="heading">How Does Satta King Work? And How to Become Famous in India?</h2>
        <p className="content">
          Satta King operates on a simple premise: participants choose a number from a set range (usually between 00 and 99). Once all the bets are placed, a random number is drawn, and the person who has bet on that number is declared the winner, often referred to as the "Satta King."
        </p>
      </section>

      <section className="section">
        <h2 className="heading">Legal Perspective</h2>
        <p className="content">
          Gambling laws in India are complex and vary by state. While games of skill are allowed under certain conditions, betting on random numbers, as in the case of Satta King, is generally prohibited. The Public Gambling Act of 1867 serves as the central law prohibiting public gambling houses, but enforcement varies.
        </p>
      </section>

      <section className="section">
        <h2 className="heading">Government Actions</h2>
        <p className="content">
          In recent years, governments in states like Haryana (Faridabad) and Uttar Pradesh (Ghaziabad) have intensified crackdowns on Satta King Operations. However, the clandestine nature of the game and its deep-rooted networks often make these efforts challenging.
        </p>
      </section>

      <section className="section">
        <h2 className="heading">How We Can Navigate to the Results</h2>
        <p className="content">
          Once on the homepage, look for a section labeled "Results" or "Satta King Live Results." This section is typically prominently displayed due to its popularity. Then if you want to view the result for the current month or the previous months. There you can see results for Mahakaal, Nva Savera, Delhi Bajar, Super King, Shree Ganesh, Jai Luxmi.
        </p>
      </section>

      

      <section className="section">
        <h2 className="heading">What is Nva Savera Satta?</h2>
        <p className="content">
          Satta King is a kind of lottery game based on numbers from 00 to 99 which comes under "Gambling". The real name of the game is Satta King, in which "Satta" means betting or gambling, and "Nva Savera" means a pot through which a number is drawn out. In the Nva Savera Satta game, people wager money on their chosen numbers from between 00 to 99. After which, a number is drawn out of the pot. Whichever person's number was drawn out, he would win the prize and people called him the Nva Savera Satta.
        </p>
      </section>

      <section className="conclusion">
        <h2 className="heading">Conclusion</h2>
        <p className="content">
          While Nva Savera Satta King continues to thrive in regions like Faridabad, Ghaziabad, UP, Himalaya, Himachal, Gali, Disawer, and Punjab, it is important to recognize the significant risks associated with this form of gambling. The allure of quick money comes at the cost of legal troubles, financial damage, and social degradation. As the government and law enforcement agencies work to curb this illegal activity, individuals need to understand the consequences and avoid such practices.
        </p>
        <p className="content">
          If you are focused on learning more about the legal connection of gambling in India or seeking help for gambling habits, a great number of resources are available to provide support and guidance.
        </p>
      </section>

      <footer className="footer">
         
        <p className="footer-content">
          We provide the latest updates on Satta King Results and information about the game for educational purposes. Our goal is to inform, not to encourage gambling.
        </p>
        <p className="legal-disclaimer">
          Legal Disclaimer: Nva Savera Satta King is illegal in many regions. We do not promote or encourage participation in illegal gambling activities. Please check the laws in your area and gamble responsibly.
        </p>
      </footer>
      
    </div>
    </div>
        </div>

    </div>

      </>
  );
}

export default About;
